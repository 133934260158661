import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Notification from "../../../components/notifications";

export default {
  name: "TLoanPaidSimulation",
  data() {
    return {
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        animation: {
          processSimulationSettled: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        request: {
          loanAccountNumber: "",
          cifIdName: "",
          officeName: "",
          timePeriod: "",
          rateMarginTotal: "",
          debtDisc: 0,
          unpaid: "",
        },
        result: {
          settledDate: "",
          installmentPeriod: 0,
          remainingDebt: 0,
          remainingMargin: 0,
          totalSettled: 0,
          debtDisc: 0,
        },
      },
    };
  },
  methods: {
    handleSearchLoanAccountNumber() {
      this.$buefy.dialog.prompt({
        title: "Cari Nomor Kontrak",
        message: `Masukkan Nomor Kontrak Pembiayaan`,
        type: "is-success",
        inputAttrs: {
          placeholder: "Nomor kontrak",
        },
        trapFocus: true,
        cancelText: `Batal`,
        confirmText: "Cari",
        closeOnConfirm: false,
        onConfirm: async (accountNumber, { close }) => {
          if (accountNumber) {
            try {
              const resp = await this.$store.dispatch({
                type: "GET_DATA_FIND_BY",
                endPoint: "loan",
                reqUrl:
                  "loan-installment/findByLoanAccountNumber/" + accountNumber,
              });
              if (resp.data.code === "SUCCESS") {
                close();
                this.dataForm.request.loanAccountNumber =
                  resp.data.data.loanAccountNumber;
                this.dataForm.request.cifIdName = resp.data.data.cifIdName;
                this.dataForm.request.officeName = resp.data.data.officeName;
                this.dataForm.request.timePeriod = resp.data.data.timePeriod;
                this.dataForm.request.unpaid = resp.data.data.unpaid;
                this.dataForm.request.rateMarginTotal =
                  resp.data.data.rateMarginTotal;
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
            }
          }
        },
      });
    },

    async handleProcessSimulationSettled() {
      const payload = {
        loanAccountNumber: this.dataForm.request.loanAccountNumber,
        debtDisc: this.dataForm.request.debtDisc,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.processSimulationSettled.isLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "loan",
            reqUrl: "loan-installment/simulation/settled",
            payload: payload,
          });
          if (resp.data.code === "SUCCESS") {
            this.handleClearInputResultSimulationSettled();
            setTimeout(() => {
              this.dataForm.result.settledDate = resp.data.data.settledDate;
              this.dataForm.result.installmentPeriod =
                resp.data.data.installmentPeriod;
              this.dataForm.result.remainingDebt = resp.data.data.remainingDebt;
              this.dataForm.result.remainingMargin =
                resp.data.data.remainingMargin;
              this.dataForm.result.totalSettled = resp.data.data.totalSettled;
              this.dataForm.result.debtDisc = resp.data.data.debtDisc;
            }, 1000);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          setTimeout(() => {
            this.property.animation.processSimulationSettled.isLoading = false;
          }, 1000);
        }
      }
    },
    handleClearInputResultSimulationSettled() {
      this.dataForm.result.settledDate = "";
      this.dataForm.result.installmentPeriod = "";
      this.dataForm.result.remainingDebt = "";
      this.dataForm.result.remainingMargin = "";
      this.dataForm.result.totalSettled = "";
      this.dataForm.result.debtDisc = "";
    },
    handlePrintPaidSimulation() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(10)
        .text("Data Simulasi Pelunasan", pageWidth / 2, 0.8, "center");
      doc
        .setFontSize(8)
        .text(
          `Tanggal Pelunasan        :   ${
            this.dataForm.result.settledDate
              ? new Date(this.dataForm.result.settledDate).getDate()
              : ""
          } ${
            this.dataForm.result.settledDate
              ? this.property.accessories.day[
                  new Date(this.dataForm.result.settledDate).getMonth()
                ]
              : ""
          } ${
            this.dataForm.result.settledDate
              ? new Date(this.dataForm.result.settledDate).getFullYear()
              : ""
          }`,
          0.55,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Sisa Jangka Waktu        :   ${this.dataForm.result.installmentPeriod} Bulan`,
          0.55,
          1.5
        );
      doc
        .setFontSize(8)
        .text(
          `Total Sisa Pokok           :   ${
            this.dataForm.result.remainingDebt
              ? this.dataForm.result.remainingDebt
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          1.7
        );
      doc
        .setFontSize(8)
        .text(
          `Sisa Margin                   :   ${
            this.dataForm.result.remainingMargin
              ? this.dataForm.result.remainingMargin
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          1.9
        );
      doc
        .setFontSize(8)
        .text(
          `Total Pelunasan            :   ${
            this.dataForm.result.totalSettled
              ? this.dataForm.result.totalSettled
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          } `,
          0.55,
          2.1
        );

      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
  },
};
